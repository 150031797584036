<template>
  <el-dialog title="编辑序列号" :visible.sync="visible" :close-on-click-modal="false">
    <el-form v-loading="formLoading" :model="form" ref="form" label-width="100px" :rules="rules">
      <el-row>
        <el-col :span="12">
          <el-form-item label="产品名称" prop="name">
            <el-select v-model="form.name" filterable @change="handleChange" value-key="id" style="width:100%">
              <el-option v-for="item in list_Product" :key="item.id" :label="item.productName" :value="item">
                <el-row type="flex" justify="space-between">
                  <span>{{item.productName}}</span>
                  <span>{{item.productType}}</span>
                </el-row>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="序列号" prop="serialNumber">
            <el-input v-model="form.serialNumber" readonly @blur="handleBlur">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="出厂日期" prop="dateOfProduction">
            <el-date-picker v-model="form.dateOfProduction" placeholder="请选择日期" style="width:100%"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="版本" prop="edition">
            <el-input v-model="form.edition"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="过期天数" prop="expiresDay">
            <el-input v-model.number="form.expiresDay"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密钥" prop="pwd">
            <el-input v-model="form.pwd" @focus="handleBlur"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="贴牌公司名称" prop="companyName">
            <el-select v-model="form.companyName" filterable style="width:100%">
              <el-option v-for="(item,index) in companyNameList" :key="index" :value="item.dataText"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="型号" prop="specification">
            <el-select v-model="form.specification" filterable style="width:100%">
              <el-option v-for="(item,index) in specificationList" :key="index" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="经销商" prop="agentId">
            <el-select v-model="form.agentId" filterable value-key="id" style="width:100%">
              <el-option v-for="(item,index) in list_Agent" :key="index" :label="item.companyName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="编号" prop="bianHao">
            <el-input v-model="form.bianHao"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="过期日期" prop="expiresDate">
            <el-date-picker v-model="form.expiresDate" placeholder="请选择日期" style="width:100%"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="创建人" prop="createrName">
            <el-input v-model="form.createrName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="允许使用软件" prop="remark">
            <el-switch v-model="form.useLCWY">
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button type="primary" @click="updateModel">保存</el-button>
        <el-button @click="visible=false">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      formLoading: false,
      list_Product: [],
      list_Agent: [],
      specificationList: [],
      companyNameList: [],
      form: {},
      rules: {
        name: [{ required: true, message: "必填" }],
        serialNumber: [{ required: true, message: "必填" }],
        dateOfProduction: [{ required: true, message: "必填" }],
        edition: [{ required: true, message: "必填" }],
        expiresDay: [{ type: "number", required: true, message: "请输入数字" }],
      },
    };
  },
  methods: {
    edit(row) {
      let that = this;
      that.visible = true;
      that.formLoading = true;
      that.axios
        .all([
          that.getProductList(),
          that.getCompanyNameList(),
          that.getModel(row.id),
          that.getAgentList(),
        ])
        .then(
          that.axios.spread(function (
            response1,
            response2,
            response3,
            response4
          ) {
            that.list_Product = response1.data.data;
            that.companyNameList = response2.data.data;
            that.form = response3.data.data;
            that.list_Agent = response4.data.data;
            that.getSpecificationList(that.form.productId);
            that.formLoading = false;
            console.log(response3.data.data);
          })
        );
    },
    updateModel() {
      let that = this;
      that.$refs.form.validate((valid) => {
        if (valid) {
          that.formLoading = true;
          that.axios
            .post("Base_Device/UpdateModel", that.form)
            .then(function () {
              that.formLoading = that.visible = false;
              that.$parent.getList();
            });
        } else return false;
      });
    },
    getProductList() {
      let that = this;
      return that.axios.get("Product/GetALLWithPId");
    },
    getSpecificationList(id) {
      let that = this;
      that.axios
        .post("Product/GetSpecificationList", { onlyInt: id })
        .then(function (response) {
          that.specificationList = response.data.data;
        });
    },
    getCompanyNameList() {
      let that = this;
      return that.axios.post("Base_SystemBaseData/GetListByDataType", {
        dataType: "贴牌公司名称",
      });
    },
    getAgentList() {
      let that = this;
      return that.axios.post("Base_Agent/GetListAll");
    },
    handleChange(v) {
      let that = this;
      that.form.deviceType = v.productType;
      that.form.name = v.productName;
      that.form.productId = v.id;
      that.getSpecificationList(v.id);
      that.handleBlur();
    },
    getModel(id) {
      let that = this;
      return that.axios.post("Base_Device/GetModel", { id: id });
    },
    handleBlur() {
      let that = this;
      that.axios
        .post("Base_Device/GetPWD", {
          serialNumber: that.form.serialNumber,
          productId: that.form.productId,
        })
        .then(function (response) {
          that.form.pwd = response.data.data;
        });
    },
  },
};
</script>

<style>
</style>