<template>
  <div>
    <el-row type="flex" :gutter="2">
      <el-select v-model="operate" @change="v=>{currentPage=1; getList();}">
        <el-option label="已入库" value="入库"></el-option>
        <el-option label="已出库" value="出库"></el-option>
        <el-option label="全部" value="全部"></el-option>
      </el-select>
      <el-col :span="8">
        <el-input v-model="serialNumber" @keyup.enter.native="v=>{currentPage=1; getList();}">
          <template #prepend>名称/序列号</template>
          <el-button slot="append" icon="el-icon-search" @click="v=>{currentPage=1; getList();}">
          </el-button>
        </el-input>
      </el-col>
      <el-button type="success" @click="$refs.addDialog.add()">增加</el-button>
      <el-dropdown :show-timeout="0" split-button type="primary" @command="v=>{printByWinform(4,v)}" @click="printByWinform(4,'0')" style="margin-left: 2px;">
        打印内包装(横)
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">编辑模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 
      <el-dropdown :show-timeout="0" split-button type="primary" @click="printByWinform(0)" style="margin-left: 2px;">
        打印内包装(竖)
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>编辑模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <el-dropdown :show-timeout="0" split-button type="primary" @command="v=>{printByWinform(1,v)}" @click="printByWinform(1,'0')" style="margin-left: 2px;">
        打印外包装
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">编辑模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown :show-timeout="0" split-button type="primary" @command="v=>{printByWinform(2,v)}" @click="printByWinform(2,'0')" style="margin-left: 2px;">
        打印合格证
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">编辑模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown :show-timeout="0" split-button type="primary" @command="v=>{printByWinform(3,v)}" @click="printByWinform(3,'0')" style="margin-left: 2px;">
        打印说明书
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">编辑模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-button type="success" @click="printByWinform(0)">打印内包装(竖)</el-button>
      <el-button type="success" @click="printByWinform(1)">打印外包装</el-button>
      <el-button type="success" @click="printByWinform(2)">打印合格证</el-button>
      <el-button type="success" @click="printByWinform(3)">打印说明书</el-button> -->
    </el-row>
    <el-table ref="elTable" :data="tableData" v-loading="tableLoading" @row-dblclick="row=>$refs.editDialog.edit(row)">
      <el-table-column type='selection'></el-table-column>
      <el-table-column prop="name" label="产品名称"></el-table-column>
      <el-table-column prop="deviceType" label="产品类型" width="80"></el-table-column>
      <el-table-column prop="serialNumber" label="序列号" width="100"></el-table-column>
      <el-table-column prop="dateOfProduction" label="出厂日期" width="100" :formatter="v=>{return v.dateOfProduction.substr(0,10);}"></el-table-column>
      <el-table-column prop="specification" label="型号"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column prop="insertTime" label="入库时间" width="150"></el-table-column>
      <el-table-column prop="operate" label="状态" width="50"></el-table-column>
      <el-table-column label="操作" width="70">
        <template #default="scope">
          <el-button-group>
            <el-button type="primary" size="mini" @click="viewDetail(scope.row)">明细</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="getList" background :total="total" :current-page.sync="currentPage"></el-pagination>
    <el-dialog :visible.sync="dialogVisible" :title="detail.length>0?detail[0].deviceName+'('+detail[0].serialNumber+')':''">
      <el-timeline>
        <el-timeline-item v-for="(item,index) in detail" :key="index" :timestamp="item.insertTime">
          <el-row type="flex">
            <span>操作：<el-tag size="mini">{{item.operate}}</el-tag></span>；<span>操作人：{{item.insertUserName}}</span>；<span>备注：{{item.remark?item.remark:"-"}}</span>
          </el-row>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
    <add-dialog ref="addDialog"></add-dialog>
    <edit-dialog ref="editDialog"></edit-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EditDialog from "../../SystemSetting/Device/EditDialog";
import AddDialog from "../../SystemSetting/Device/AddDialog.vue";
export default {
  components: { EditDialog, AddDialog },
  data() {
    return {
      operate: "入库",
      currentPage: 1,
      total: 0,
      serialNumber: "",
      tableLoading: false,
      tableData: null,
      dialogVisible: false,
      detail: [],
    };
  },
  computed: { ...mapState(["currentUser"]) },
  methods: {
    ...mapActions(["getCurrentUser"]),
    getList() {
      let that = this;
      that.tableLoading = true;
      that.axios
        .post("ProductWarehouse/GetDeviceWarehouseList", {
          serialNumber: that.serialNumber,
          operate: that.operate,
          currentPage: that.currentPage,
          total: that.total,
        })
        .then(function (response) {
          that.tableLoading = false;
          that.total = response.data.data.total;
          that.tableData = response.data.data.data;
        });
    },
    viewDetail(row) {
      let that = this;
      const tmpLoading = that.$loading();
      that.axios
        .post("ProductWarehouse/GetListBySerialNumber", {
          onlyText: row.serialNumber,
        })
        .then(function (response) {
          that.detail = response.data.data;
          tmpLoading.close();
          that.dialogVisible = true;
        });
    },
    print(type) {
      let that = this;
      if (that.$refs.elTable.selection.length == 0) {
        that.$alert("请选中行");
      } else {
        let ids = "";
        that.$refs.elTable.selection.forEach((element) => {
          ids += element.id + ",";
        });
        window.open(
          process.env.VUE_APP_TurnUrl + "Device/Print" + type + "?ids=" + ids
        );
      }
    },
    initWebSocket() {
      const wsuri = process.env.VUE_APP_WebSocketURL;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      if (e.data === "0") {
        let that = this;
        that.$alert("请启动本地打印驱动");
      }
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose() {
      //关闭
      this.$alert("请刷新页面");
    },
    printByWinform(file, type) {
      let that = this;
      if (that.$refs.elTable.selection.length == 0) {
        that.$alert("请选中行");
      } else {
        let ids = "";
        that.$refs.elTable.selection.forEach((element) => {
          ids += element.id + ",";
        });
        that.websocketsend(
          JSON.stringify({
            deviceType: "SUMJ01",
            serialNumber: that.currentUser.localPrintSerialNumber,
            //serialNumber: "60771B09",//河北小电脑
            //serialNumber: "60815108", //河北文员电脑
            command: "33",
            data: file + type + ids,
          })
        );
      }
    },
  },
  mounted() {
    let that = this;
    that.getList();
    that.initWebSocket();
    that.getCurrentUser();
  },
};
</script>

<style>
</style>